<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.update_partner_clinic") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submitTransaction(true)"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-form ref="form" :model="form" :rules="rules" class="aticler_m">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item :label="columns.name.title" prop="name">
            <crm-input
              v-model="form.name"
              :inputValue="form.name"
              autocomplete="off"
            ></crm-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="columns.phone_number.title" prop="phone_number">
            <crm-input
              v-model="form.phone_number"
              :maskFormat="'tel'"
              :inputValue="form.phone_number"
              autocomplete="off"
            ></crm-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item
            :label="columns.responsible_name.title"
            prop="responsible_name"
          >
            <crm-input
              v-model="form.responsible_name"
              :inputValue="form.responsible_name"
              autocomplete="off"
            ></crm-input>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <div class="el-form-item__label w-100 text-left">
             {{$t('message.partner_clinic_full_logo')}}
          </div>
          <div class="imgUpload">
            <el-upload
              :action="base_url + 'clinicImages/storeFullImage'"
              :limit="1"
              accept="image/*"
              name="image"
              list-type="picture-card"
              :on-remove="handleRemove"
              :file-list="fullImageList"
              :on-success="handleAvatarSuccessFull"
              :headers="{
                Authorization: 'Bearer ' + $store.getters.token,
              }"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisibleFull">
              <img width="100px" :src="dialogFullImageUrl" alt />
            </el-dialog>
          </div>
        </el-col>

        <el-col :span="4">
          <span class="el-form-item__label">{{$t('message.partner_clinic_mini_logo')}}</span>
          <div class="imgUpload">
            <el-upload
              :action="base_url + 'clinicImages/storeImage'"
              :limit="1"
              accept="image/*"
              name="image"
              list-type="picture-card"
              :on-remove="handleRemove"
              :file-list="imageList"
              :on-success="handleAvatarSuccess"
              :headers="{
                Authorization: 'Bearer ' + $store.getters.token,
              }"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100px" :src="dialogImageUrl" alt />
            </el-dialog>
          </div>
        </el-col>
        <!-- ------------------------------- -->

        <!-- end col -->
        <el-col :span="8">
          <el-radio-group v-model="form.type_blank">
            <el-radio :label="'FULL_LOGO'">{{$t('message.partner_clinic_full_logo_active')}}</el-radio>
            <el-radio :label="'HALF_LOGO'"
              >{{$t('message.partner_clinic_mini_logo_active')}}</el-radio
            >
          </el-radio-group>
        </el-col>

        <el-col :span="8">
          <el-select
            class="w-100"
            size="small"
            v-model="form.departments"
            multiple
            clearable
            filterable
            allow-create
            default-first-option
            :placeholder="$t('message.department')"
          >
            <el-option
              v-for="item in departments"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <div class="block mt-4">
        <el-tree
          class="filter-tree"
          :data="data"
          show-checkbox
          node-key="id"
          :filter-node-method="filterNode"
          ref="items"
          @node-click="handleNodeClick"
          :expand-on-click-node="true"
        >
          <span
            :style="`background: ${data.color}`"
            class="custom-tree-node"
            slot-scope="{ node, data }"
          >
            <span class="aaa my-tree-khan">
              <div>{{ data.name }}</div>
              {{ data.amount_type }}
              <!-- <select
                placeholder="'Класс'"
                v-model="data.amount_type"
                :disabled="!node.checked"
                size="small"
                class="my-kh ml-3"
                style="border: 1px solid #dcdfe6; border-radius: 4px"
              >
                <option
                  v-for="item in classes"
                  :key="item.name"
                  :label="item.name"
                  :value="item.type"
                ></option>
              </select> -->
              <input
                :disabled="!node.checked"
                v-model="data.amount"
                :placeholder="'sum'"
                ref="'data_amount'+ node.id"
                class="my-kh el-input__inner2"
                size="small"
              />
            </span>
          </span>
        </el-tree>
      </div>
      <!-- end row -->
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import drawerChild from "@/utils/mixins/drawer-child";
export default {
  mixins: [form, drawerChild],
  props: {
    reloadModel: { type: Boolean, default: true },
  },
  data() {
    return {
      url: process.env.VUE_APP_URL_DOCS,
      data: [],
      services: [],
      fileList: [],
      imageList: [],
      fullImageList: [],
      dialogImageUrl: "",
      dialogFullImageUrl: "",
      dialogVisible: false,
      dialogVisibleFull: false,
      drawerPatientService: false,
      defaultProps: {
        children: "children",
        label: "label",
        price: "price",
      },
    };
  },

  created() {},

  computed: {
    ...mapGetters({
      model: "partnerClinic/model",
      rules: "partnerClinic/rules",
      columns: "partnerClinic/columns",
      departments: "department/list",
    }),
  },

  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.loadDepartment();
  },
  methods: {
    ...mapActions({
      destroy: "clinicImages/destroy",
      loadDepartment: "department/index",
      servicesRealtionList: "services/relationList",
      save: "partnerClinic/update",
    }),
    async afterOpened() {
      if (this.form.image) {
        this.form.image = this.url + this.form.image.image;
      }

      if (this.form.image) {
        this.imageList.push({
          url: this.form.image,
        });
      }

      if (this.form.full_image) {
        this.fullImageList.push({
          url: this.base_url_docs + "" + this.form.full_image.image,
        });
      }

      const query = { relation: true };
      await this.servicesRealtionList(query)
        .then((res) => {
          this.data = res.data.services;
        })
        .catch((err) => {});
      await this.form.clinic_service.forEach((serv) => {
        this.data.forEach((child) => {
          if (child.children.find((x) => x.id == serv.service_id)) {
            child.children.find((x) => x.id == serv.service_id).amount =
              serv.amount;
            child.children.find((x) => x.id == serv.service_id).amount_type =
              serv.amount_type;
          }
        });

        if (this.data.find((x) => x.id == serv.service_id)) {
          this.data.find((x) => x.id == serv.service_id).amount = serv.amount;
          this.data.find((x) => x.id == serv.service_id).amount_type =
            serv.amount_type;
        }

        if (this.$refs.items) {
          if (
            this.$refs.items.root.childNodes.find(
              (x) => x.data.id == serv.service_id
            )
          )
            this.$refs.items.root.childNodes.find(
              (x) => x.data.id == serv.service_id
            ).checked = true;

          this.$refs.items.root.childNodes.forEach((item) => {
            if (item.childNodes.find((x) => x.data.id == serv.service_id))
              item.childNodes.find(
                (x) => x.data.id == serv.service_id
              ).checked = true;
          });
        }
      });
    },
    afterClosed() {
      this.resetForm("form");
      this.$refs.items.setCheckedNodes([]);
      this.data = [];
    },
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      return dd;
    },
    handleNodeClick(node, data, value) {
      this.services = $.grep(this.services, function (e) {
        return e.id != data.data.id;
      });
      this.services.push(data.data);
    },
    submitTransaction(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.form.services = this.$refs.items.getCheckedNodes();
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              this.parent().listChanged();
              this.resetForm("form");
              this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
            });
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.form.clinic_image_id = res.data.clinicImages.id;
    },
    handleAvatarSuccessFull(res, file) {
      this.form.clinic_full_image_id = res.data.clinicImages.id;
    },
    handleAvatarSuccessFile(res, file) {
      this.form.patient_file_id = res.data.patientFiles.id;
    },

    handleRemove(file, fileList) {
      this.destroy(file.response.data.clinicImages.id).then((res) => {});
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handlePictureCardPreviewFull(file) {
      this.dialogFullImageUrl = file.url;
      this.dialogVisibleFull = true;
    },
    handlePreview(file) {
      console.log(file);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.data = [];
      this.imageList = [];
      this.fullImageList = [];
      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
  },
};
</script>
<style>
.el-input__inner2 {
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 32px;
  line-height: 32px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.el-input__inner2:disabled {
  background: #f6f8fb;
}
</style>